<template>
  <div class="bidding-detail">
    <div class="breadcrumb" v-show="!isFullscreen">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item> 行业信息库 </el-breadcrumb-item>
        <el-breadcrumb-item to="/industry/bidding-information">招标信息库</el-breadcrumb-item>
        <el-breadcrumb-item> 招标信息详情 </el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="file-box">
      <div class="file-name-box">
        <div class="file-name-left">
          <!-- <div class="file-img"></div> -->
          <div>
            <el-tooltip effect="light" :content="detailData.projectName" placement="bottom-start">
              <div class="file-name text-ellipsis">
                {{ detailData.projectName }}
              </div>
            </el-tooltip>
          </div>
          <div class="download-box" @click="click">
            <span :class="isFullscreen
                ? 'iconfont icon-quxiaoquanping'
                : 'iconfont icon-quanping'
              "></span>
            <span>{{ isFullscreen ? "取消全屏" : "全屏预览" }}</span>
          </div>
        </div>
        <div class="file-name-right" @click="toOriginal(detailData.projectLink)">
          查看原文
        </div>
      </div>
      <div :class="isFullscreen
          ? 'full-file-content ql-editor'
          : 'file-content ql-editor'
        " v-html="detailData.contents"></div>
    </div>
    <ToPayOrMember ref="toPayOrMember" :showCommonText="false" :showPayBtn="showPayBtn" :text="text"></ToPayOrMember>
  </div>
</template>

<script>
import ToPayOrMember from "@/components/ToPayOrMember"
export default {
  components: {
    ToPayOrMember
  },
  data() {
    return {
      showPayBtn: false,
      detailData: {},
      isFullscreen: false,
      text: ''
    };
  },
  mounted() {
    let id = this.$route.query.id;
    this.getTenderInfoDetail(id);
  },
  methods: {
    getTenderInfoDetail(id) {
      const loading = this.$loading({
        lock: true,
        text: "加载中...",
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.3)",
        fullscreen: false
      });
      this.$api.industry
        .getTenderInfoDetail({
          platform: 1,
          tenderId: id
        })
        .then(res => {
          this.detailData = res.data;
        })
        .catch(msg => {
          if (msg.code == '10001111') {
            this.$refs.toPayOrMember.openDialog()
            this.text = msg?.msg
          } else {
            this.$message.error(msg?.msg);
          }
        })
        .finally(() => {
          loading.close();
        });
    },
    // 网页全屏方法
    click() {
      if (this.isFullscreen) {
        this.isFullscreen = !this.isFullscreen;
        document.querySelector(".file-box").style.width = "1200px";
      } else {
        this.isFullscreen = !this.isFullscreen;
        document.querySelector(".file-box").style.width = "100vw";
      }
    },
    // 查看原文
    toOriginal(url) {
      this.$confirm(
        "您即将跳转进入第三方网站，慧决策平台不对该网站内容及真实性负责，请注意您的账号和财产安全",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }
      ).then(() => {
        window.open(url, "_blank");
      });
    }
  }
};
</script>

<style lang="less">
@import "./index.less";
</style>
